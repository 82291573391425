import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Carousel from '../components/carousel';
import Hero from './../components/hero';
import Intro from './../components/intro';
import Benefits from './../components/benefits';
import Importance from './../components/importance';
import HowTo from './../components/howto';
import Highlight from './../components/highlight';
import Monetize from './../components/monetize';
import CTAComponent from './../components/cta';

export default function IndexPage({ data }) {
    const {
        seo_content,
        hero,
        awards_carousel_repeater,
        home_intro,
        features,
        importance,
        how_to_work,
        highlight,
        data_partners,
        book_now,
    } = data.butterPage;

    return (
        <Layout menu="home">
            <SEOComponent
                title=""
                image={seo_content && seo_content.sharing_image}
                description={seo_content && seo_content.seo_description}
            />
            
            <Hero {...hero[0]} hasVideo={true} children ={<Carousel data={awards_carousel_repeater}/>} ></Hero>
            <Intro {...home_intro[0]} />
            <Benefits {...features[0]} />
            <Importance {...importance[0]}></Importance>
            <HowTo title={'How to Work with Us'} items={how_to_work}></HowTo>
            <Highlight {...highlight[0]}></Highlight>
            <Monetize {...data_partners[0]}></Monetize>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}
export const indexPageQuery = graphql`
    query {
        butterPage(slug: { eq: "home" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                description
                desktop_image
                mobile_image
                mask_image
                video_url
            }
            awards_carousel_repeater {
                title
                description
                image
            }
            home_intro {
                title
                description
                image
                mobile_image
                features {
                    title
                    description
                }
                features_second_row {
                    title
                    description
                }
            }
            features {
                title
                description
                features {
                    title
                    image
                }
            }
            importance {
                title
                description
                features {
                    title
                    description
                    image
                }
            }
            how_to_work {
                title
                description
                image
                url
                button_title
            }
            highlight {
                name
                title
                description
                image
                url
                button_title
            }
            data_partners {
                title
                first_row {
                    image
                }
                second_row {
                    image
                }
            }
            book_now {
                title
                button_title
            }
        }
    }
`;
