import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-family: 'Renogare-Regular';
    font-size: ${props => `${props.fontSize}px`};
    text-align: left;
    color: white;
    line-height: 24px;
    letter-spacing: normal;
    user-select: none;
    background: linear-gradient(0.25turn, #996b3d, #ccaa66, #ccaa66);
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
`;
export default function GoldenText({ fontSize = 13.5, children }) {
    return <Container fontSize={fontSize}>{children}</Container>;
}
