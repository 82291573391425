import React from 'react';
import styled from 'styled-components';
import { Player, ControlBar } from 'video-react';
import useMedia from 'use-media';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    margin: 0;
    color: white;
    text-align: center;
    background: white;
    width: 100%;
    height: 118vh;
    position: relative;
    @media screen and (max-width: 960px) {
        height: 100vh;
    }

    @media screen and (max-width: 480px) {
        height: 90vh;
    }
`;

const DesktopLayout = styled.div`
    display: block;
    position: relative;
    /* padding-top: 56.25%;  */
    width: auto;
    overflow: hidden;
    height: 100%;
    & > div {
        padding-top: 0 !important;
        div {
            display: none;
        }
        button {
            display: none;
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            outline: none;
            user-select: none;
        }
        .video-react-big-play-button {
            display: none;
        }
    }
    .video-react-poster {
        background-size: cover !important;
    }
    /* min-height: 900px; */
    background-color: #1d1b1e;
    z-index: 0;
    @media screen and (max-width: 1440px) {
        /* min-height: 900px; */
    }
    @media screen and (max-width: 956px) {
        display: none;
    }
`;

const MobileLayout = styled.div`
    display: none;
    @media screen and (max-width: 956px) {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
    }
`;

const Content = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
    @media screen and (max-width: 956px) {
        /* max-width: 500px; */
        /* display: none; */
    }
`;

const Title = styled.div`
    margin-top: auto;

    font-family: 'Renogare-Regular';
    font-size: 80px;
    line-height: 80px;
    font-weight: bold;
    letter-spacing: -2px;
    max-width: 864px;
    margin-left: auto;
    margin-right: auto;

    & p {
        margin: 0;
    }

    & img {
        width: 100%;
    }

    @media screen and (max-width: 1440px) {
        padding-top: 70px;
        font-size: 70px;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 100px;
        font-size: 60px;
        max-width: 600px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 40px;
        font-size: 44px;
        line-height: 48px;
        max-width: calc(100% - 48px);
    }
    @media only screen and (max-device-width: 480px) {
        padding-top: 50px;
    }
`;

const Desc = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 32px;
    line-height: 38px;
    user-select: none;
    margin: 0 auto;
    width: 52%;
    @media screen and (max-width: 767px) {
        max-width: calc(100% - 48px);
        margin-left: 24px;
        margin-bottom: -40px;
        width: auto;
    }
`;

const MaskContainer = styled.div`
    width: 100%;
    height: 100px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: -1px;
    img {
        object-fit: auto;
    }
    @media screen and (max-width: 767px) {
        img {
            object-fit: cover;
        }
    }
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
`;

export default function Hero({
    title,
    description,
    desktop_image,
    mobile_image,
    mask_image,
    video_url,
    children = {}
}) {
    const isWide = useMedia({ minWidth: 956 });

    return (
        <Container>
            <Content>
                <Title dangerouslySetInnerHTML={{__html: title}} />
                <Desc>{description}</Desc>
                {children}
                <MaskContainer>
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        width="100%"
                        height={100}
                        src={mask_image} // use normal <img> attributes as props
                        style={{
                            width: '100%',
                            height: 100,
                            marginTop: 'auto',
                        }}
                    />
                </MaskContainer>
            </Content>
            {isWide ? (
                <DesktopLayout>
                    <Player
                        poster={desktop_image}
                        fluid={false}
                        height={'100%'}
                        width={'100%'}
                        autoPlay={true}
                        loop={true}
                        preload="auto"
                    >
                        <source src={video_url} width="100%" />
                        <ControlBar autoHide={false} />
                    </Player>
                </DesktopLayout>
            ) : (
                <MobileLayout>
                    <Overlay />
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        width="100%"
                        height="100%"
                        src={mobile_image} // use normal <img> attributes as props
                        style={{ objectFit: 'cover' }}
                    />
                </MobileLayout>
            )}
        </Container>
    );
}
