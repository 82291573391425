import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    display: block;
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 68px;
    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 28px;
    }
`;
const Heading = styled.div`
    color: #ffffff;
    font-family: 'Renogare-Regular';
    font-size: 36px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
    }
`;
const Content = styled.div`
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
`;
const Benefit = styled.div`
    width: calc(25% - 9px);
    padding: 0 4px;
    border-right: 1px rgba(255, 255, 255, 0.1) solid;
    /* padding: 0 32px; */
    text-align: center;
    &:last-child {
        border-right: none;
    }
    @media screen and (max-width: 767px) {
        width: calc(50% - 9px);
        &:nth-child(1) {
            border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
        }
        &:nth-child(2) {
            border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
            border-right: none;
        }
    }
`;
const BenefitIcon = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    user-select: none;
    @media screen and (max-width: 767px) {
        margin-top: 24px;
    }
`;
const BenefitDesc = styled.div`
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    user-select: none;
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;
    @media screen and (max-width: 767px) {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 24px;
    }
`;
const Description = styled.div`
    color: #ffffff;
    font-family: 'Neuzeit Grotesk';
    font-size: 24px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: center;
    margin-top: 12px;
    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
`;
export default function Benefits({ features, description, title }) {
    return (
        <Container>
            <Heading>{title}</Heading>
            <Description>{description}</Description>
            <Content>
                {features.map(item => {
                    return (
                        <Benefit key={item.title}>
                            <BenefitIcon>
                                <LazyLoadImage
                                    src={item.image}
                                    alt={item.title}
                                    style={{ objectFit: 'contain' }}
                                    height="100%"
                                />
                            </BenefitIcon>
                            <BenefitDesc>{item.title}</BenefitDesc>
                        </Benefit>
                    );
                })}
            </Content>
        </Container>
    );
}
