import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 450px;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin: 25px 0 0 0;

    @media screen and (max-width: 768px) {
        width: 250px;
        margin-top: 15px;
    }

    @media screen and (max-width: 440px) {
        display: none;
    }

    @media screen and (max-height: 650px) {
        display: none;
    }
`;

const SlideContainer = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 170px;
`;

const CarouselItem = styled.div`
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.current ? 1 : 0)};
    transition: opacity 0.5s ease-in-out;

    .carousel-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: ${props => (props.current ? 'none' : 'blur(5px)')};
        transition: filter 0.5s ease-in-out;
    }
`;

const Handler = styled.div`
    width: 50%;
    height: auto;
    position: relative;
    bottom: 10px;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 440px) {
        display: none;
    }
`;

const Circle = styled.div`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: ${p => (p.active ? '#ffffff' : '#919191')};
    @media screen and (max-width: 1440px) {
        width: 10px;
        height: 10px;
    }
    @media screen and (max-width: 1040px) {
        width: 12px;
        height: 12px;
    }
    @media screen and (max-width: 767px) {
        width: 11px;
        height: 11px;
    }
    @media screen and (max-width: 480px) {
        width: 10px;
        height: 10px;
    }
    &:hover {
        cursor: pointer;
    }
`;

const CarouselImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const Carousel = ({ data }) => {
    const [current, setCurrent] = useState(0);

    // Function to handle manual slide change
    const handleControl = index => {
        setCurrent(index);
    };

    // Function to handle autoslide
    const handleAutoslide = () => {
        setCurrent(current => (current + 1) % data.length);
    };

    useEffect(() => {
        // Set autoslide interval to change slide every 5 seconds
        const autoslideInterval = setInterval(handleAutoslide, 5000);

        // Clear autoslide interval when component unmounts or when user manually changes slide
        return () => {
            clearInterval(autoslideInterval);
        };
    }, [current]);

    return (
        <Container>
            <SlideContainer>
                {data.map((d, i) => (
                    <CarouselItem key={i} current={i === current}>
                        <CarouselImg
                            className="carousel-img"
                            src={d.image}
                            alt={d.description}
                        />
                    </CarouselItem>
                ))}
            </SlideContainer>
            <Handler>
                {data.map((d, i) => (
                    <Circle
                        key={i}
                        onClick={() => handleControl(i)}
                        active={i === current}
                    />
                ))}
            </Handler>
        </Container>
    );
};

export default Carousel;
