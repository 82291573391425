import React from 'react';
import styled from 'styled-components';
import HollowButton from 'components/buttons/HollowButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    margin-top: 120px;
    display: block;
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    @media screen and (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 80px;
        width: calc(100% - 48px);
    }
`;
const Heading = styled.div`
    font-size: 36px;
    letter-spacing: -1.5px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-family: 'Renogare-Regular';

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        color: white;
    }
`;
const Content = styled.div`
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const Item = styled.div`
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
    border-radius: 8px;
    width: 48.5%;
    height: 320px;
    background: url(${props => props.img}) no-repeat center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 240px;
        margin-bottom: 8px;
    }
`;
const ItemOverlay = styled.div`
    background: transparent;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(44, 31, 21, 0.5);
    z-index: 1;
    &:hover {
        background: rgba(0, 0, 0, 0.7);
    }
`;
const ItemHeading = styled.div`
    font-family: 'Renogare-Regular';
    color: #ffffff;
    line-height: 32px;
    letter-spacing: -0.5px;
    font-size: 20px;
    user-select: none;
`;
const ItemDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    transition: all 300ms ease-in-out;
    user-select: none;
    margin-top: 8px;
    transform: scaleY(0);
    transform-origin: top;
    margin-left: 48px;
    margin-right: 48px;
    color: white;
    & > * {
        display: none;
    }
    ${ItemOverlay}:hover > & {
        transform: scaleY(1);
        & > * {
            display: block;
        }
    }
`;
const ItemFooter = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
`;
const ItemBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
`;
export default function HowTo({ title, items }) {
    return (
        <Container>
            <Heading>{title}</Heading>
            <Content>
                {items.map(item => {
                    return (
                        <Item key={item.title}>
                            <ItemBackground>
                                <LazyLoadImage
                                    alt={title}
                                    effect="blur"
                                    src={item.image} // use normal <img> attributes as props
                                    width={'100%'}
                                    height={'100%'}
                                    style={{
                                        objectFit: 'cover',
                                        borderRadius: 8,
                                    }}
                                />
                            </ItemBackground>
                            <ItemOverlay>
                                <ItemHeading>{item.title}</ItemHeading>
                                <ItemDesc>
                                    <div>{item.description}</div>
                                </ItemDesc>
                                <ItemFooter>
                                    <HollowButton type="link" url={item.url}>
                                        {item.button_title}
                                    </HollowButton>
                                </ItemFooter>
                            </ItemOverlay>
                        </Item>
                    );
                })}
            </Content>
        </Container>
    );
}
