import React, { useState } from 'react';
import styled from 'styled-components';
import BackImg from './../../assets/images/wave.png';
import BackMobileImg from './../../assets/images/wave21-mobile.png';
import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    padding: 80px 0 80px 0;
    display: block;
    background: url(${BackImg}) no-repeat bottom;
    background-size: 100% 100%;
    padding-bottom: 70px;
    margin-bottom: 70px;
    @media screen and (max-width: 767px) {
        padding: 90px 0 40px 0;
        margin-bottom: 100px;
        background: url(${BackMobileImg}) no-repeat bottom;
        background-size: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    text-align: center;
    font-size: 36px;
    line-height: 40px;
    color: white;
    width: 60%;
    margin-left: 20%;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        width: calc(100% - 48px);
        margin-left: 24px;
    }
`;
const Row1 = styled.div`
    width: 100%;
    margin-top: 53px;
`;
const Logo = styled.div`
    height: 128px;
    width: 200px;
    margin: 8px;
    @media screen and (max-width: 767px) {
        width: 125px;
        height: 80px;
        margin: 4px;
    }
`;
export default function Monetize({ title, first_row, second_row }) {
    const [pageIsVisible, setPageIsVisible] = useState(true);

    const handleVisibilityChange = isVisible => {
        setPageIsVisible(isVisible);
    };

    return (
        <Container>
            <Heading>{title}</Heading>

            <Row1>
                <PageVisibility onChange={handleVisibilityChange}>
                    {pageIsVisible && (
                        <React.Fragment>
                            <Ticker speed={7} direction="toRight">
                                {({ index }) => (
                                    <Logo>
                                        <LazyLoadImage
                                            src={
                                                first_row[
                                                    index % first_row.length
                                                ].image
                                            }
                                            alt={
                                                first_row[
                                                    index % first_row.length
                                                ].title
                                            }
                                            style={{ objectFit: 'contain' }}
                                            width="100%"
                                            height="100%"
                                        />
                                    </Logo>
                                )}
                            </Ticker>
                            <Ticker speed={7}>
                                {({ index }) => (
                                    <Logo>
                                        <LazyLoadImage
                                            src={
                                                second_row[
                                                    index % second_row.length
                                                ].image
                                            }
                                            alt={
                                                second_row[
                                                    index % second_row.length
                                                ].title
                                            }
                                            style={{ objectFit: 'contain' }}
                                            width="100%"
                                            height="100%"
                                        />
                                    </Logo>
                                )}
                            </Ticker>
                        </React.Fragment>
                    )}
                </PageVisibility>
            </Row1>
        </Container>
    );
}
