import React from 'react';
import styled from 'styled-components';
import GoldenButton from 'components/buttons/GoldenButton';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    margin-top: 92px;
    display: block;
    width: calc(100% - 256px);
    margin-left: 64px;
    margin-right: 64px;
    padding: 64px;
    border-radius: 8px;
    background-color: #fafafa;
    @media screen and (max-width: 768px) {
        margin-top: 40px;
        width: calc(100% - 48px);
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 96px 24px;
    }
`;
const InnerContainer = styled.div`
    flex-direction: row;
    display: flex;
    background-color: #fafafa;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;
const Importances = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 0;
    @media screen and (max-width: 767px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Item = styled.div`
    display: flex;
    padding-top: 23px;
`;
const ItemImg = styled.div`
    width: 56px;
    margin-right: 16px;
`;
const ItemContent = styled.div`
    padding-bottom: 23px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    ${Item}:last-child & {
        border-bottom: none !important;
    }
`;
const ItemHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #19171a;
    user-select: none;
`;
const ItemDesc = styled.div`
    font-family: 'Neuzeit Grotesk';
    font-size: 15px;
    line-height: 20px;
    letter-spacing: normal;
    margin-top: 5px;
    color: rgba(25, 23, 26, 0.7);
    user-select: none;
`;
const Content = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: #19171a;
    user-select: none;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const Desc = styled.div`
    margin-top: auto;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    line-height: 24px;
    color: rgba(25, 23, 26, 0.9);
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 767px) {
        margin-top: 32px;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 600;
        margin-bottom: 1em;
        margin-top: 1.5em;
    }

    ul,
    ol {
        margin-bottom: 1.25em;

        li {
            margin-bottom: 0.25em;
        }
    }

    p {
        font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
        font-size: 1.25em;
        line-height: 1.58;
        margin-bottom: 1.25em;
        font-weight: 400;
        letter-spacing: -0.003em;
    }

    /* Responsive default image width */
    img {
        max-width: 100%;
        height: auto;
    }

    /* Responsive floating */
    @media only screen and (min-width: 720px) {
        .butter-float-left {
            float: left;
            margin: 0px 10px 10px 0px;
        }

        .butter-float-right {
            float: right;
            margin: 0px 0px 10px 10px;
        }
    }

    /* Image caption */
    figcaption {
        font-style: italic;
        text-align: center;
        color: #ccc;
    }

    p code {
        padding: 2px 4px;
        font-size: 90%;
        color: #c7254e;
        background-color: #f9f2f4;
        border-radius: 4px;
        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    pre {
        display: block;
        padding: 1em;
        margin: 0 0 2em;
        font-size: 1em;
        line-height: 1.4;
        word-break: break-all;
        word-wrap: break-word;
        color: #333333;
        background-color: #f5f5f5;
        font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`;
const Footer = styled.div`
    margin-top: 35px;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const InnerFooter = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-top: 16px;
        justify-content: center;
    }
`;

export default function Importance({ features, title, description }) {
    return (
        <Container>
            <InnerContainer>
                <Importances>
                    {features.map(item => {
                        return (
                            <Item key={item.title}>
                                <ItemImg>
                                    <LazyLoadImage
                                        src={item.image}
                                        alt={item.title}
                                        style={{ objectFit: 'contain' }}
                                        width="100%"
                                    />
                                </ItemImg>
                                <ItemContent>
                                    <ItemHeading>{item.title}</ItemHeading>
                                    <ItemDesc>{item.description}</ItemDesc>
                                </ItemContent>
                            </Item>
                        );
                    })}
                </Importances>
                <Content>
                    <Heading>{title}</Heading>
                    <Desc>{description}</Desc>
                    <Footer>
                        <GoldenButton type="request-demo">
                            REQUEST DEMO
                        </GoldenButton>
                    </Footer>
                </Content>
            </InnerContainer>
            <InnerFooter>
                <GoldenButton type="request-demo">REQUEST DEMO</GoldenButton>
            </InnerFooter>
        </Container>
    );
}
