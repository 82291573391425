import React from 'react';
import styled from 'styled-components';
import BackImg from './../../assets/images/wave.png';
import BackMobileImg from './../../assets/images/wave-mobile.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Container = styled.div`
    margin-top: 54px;
    display: block;
    background: url(${BackImg}) no-repeat bottom;
    background-size: 100%;
    padding-bottom: 128px;
    @media screen and (max-width: 767px) {
        background: url(${BackMobileImg}) no-repeat bottom;
        background-size: 100%;
    }
`;
const IntroContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 56px;
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
        margin-bottom: 34px;
    }
`;
const ImgContainer = styled.div`
    width: 42%;
    display: block;
    @media screen and (max-width: 767px) {
        width: 100%;
        display: none;
    }
`;
const MobileImgContainer = styled.div`
    width: 42%;
    display: none;
    @media screen and (max-width: 767px) {
        width: 100%;
        display: block;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 42%;
    z-index: 0;
    margin-top: 0;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const Subheading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 26px;
    text-align: left;
    color: white;
    line-height: 34px;
    letter-spacing: normal;
    margin-top: 32px;
    user-select: none;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const Desc = styled.div`
    margin-top: 32px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: white;
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const FeatureContainer = styled.div`
    margin-top: 16px;
    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
        &::-webkit-scrollbar {
            display: none;
        }
        margin-top: 16px;
        margin-left: 0;
        margin-right: 0;
        display: block;
        overflow-x: auto;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
`;
const FeatureContainer2 = styled(FeatureContainer)`
    justify-content: center;
`;
const Feature = styled.div`
    border-radius: 4px;
    background: #322f33;
    padding: 33px 16px;
    text-align: center;
    width: calc(20% - 48px);
    margin-right: 16px;
    @media screen and (max-width: 768px) {
        min-width: 248px;
        margin-left: 24px;
        margin-right: 0px;
    }
    &:last-child {
        @media screen and (max-width: 768px) {
            margin-right: 24px;
        }
    }
`;
const FeatureTitle = styled.div`
    background: linear-gradient(0.25turn, #996b3d, #ccaa66, #ccaa66);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 19px;
    user-select: none;
    font-family: 'Renogare-Regular';
`;
const FeatureDesc = styled.div`
    font-size: 15px;
    font-family: 'Neuzeit Grotesk';
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    user-select: none;
`;
const DesktopLayout = styled.div`
    display: block;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileLayout = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
`;

export default function Intro({
    title,
    description,
    subtitle,
    image,
    mobile_image,
    features,
    features_second_row,
}) {
    return (
        <Container>
            <IntroContainer>
                <React.Fragment>
                    <ImgContainer>
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={image} // use normal <img> attributes as props
                            width={'100%'}
                            height={'auto'}
                            style={{ objectFit: 'contain' }}
                        />
                    </ImgContainer>
                    <MobileImgContainer>
                        <LazyLoadImage
                            alt={title}
                            effect="blur"
                            src={mobile_image} // use normal <img> attributes as props
                            width={'100%'}
                            height={'auto'}
                            style={{ objectFit: 'contain' }}
                        />
                    </MobileImgContainer>
                </React.Fragment>
                <Content>
                    <div>
                        <Heading>{title}</Heading>
                        {!!subtitle && <Subheading>{subtitle}</Subheading>}
                        <Desc>{description}</Desc>
                    </div>
                </Content>
            </IntroContainer>
            <DesktopLayout>
                <FeatureContainer>
                    {features.map(item => {
                        return (
                            <Feature key={item.title}>
                                <FeatureTitle>{item.title}</FeatureTitle>
                                <FeatureDesc>{item.description}</FeatureDesc>
                            </Feature>
                        );
                    })}
                </FeatureContainer>
                <FeatureContainer2>
                    {features_second_row.map(item => {
                        return (
                            <Feature key={item.title}>
                                <FeatureTitle>{item.title}</FeatureTitle>
                                <FeatureDesc>{item.description}</FeatureDesc>
                            </Feature>
                        );
                    })}
                </FeatureContainer2>
            </DesktopLayout>
            <MobileLayout>
                <FeatureContainer>
                    {features.map(item => {
                        return (
                            <Feature key={item.title}>
                                <FeatureTitle>{item.title}</FeatureTitle>
                                <FeatureDesc>{item.description}</FeatureDesc>
                            </Feature>
                        );
                    })}
                    {features_second_row.map(item => {
                        return (
                            <Feature key={item.title}>
                                <FeatureTitle>{item.title}</FeatureTitle>
                                <FeatureDesc>{item.description}</FeatureDesc>
                            </Feature>
                        );
                    })}
                </FeatureContainer>
            </MobileLayout>
        </Container>
    );
}
