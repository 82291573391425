import React from 'react';
import styled from 'styled-components';
import GoldenButton from 'components/buttons/GoldenButton';
import GoldenText from 'components/inputs/GoldenText';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Container = styled.div`
    margin-top: 120px;
    display: block;
    background-color: #fafafa;
    @media screen and (max-width: 767px) {
        margin-top: 144px;
    }
`;
const IntroContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 80px;
    margin-right: 80px;
    padding-top: 40px;
    padding-bottom: 40px;

    color: white;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
    }
`;
const ImgContainer = styled.div`
    width: 50%;
    @media screen and (max-width: 767px) {
        margin-top: 40px;
        width: 100%;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    z-index: 0;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: white;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    color: #19171a;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const Desc = styled.div`
    margin-top: 32px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
    color: rgba(25, 23, 26, 0.7);
    white-space: pre-wrap;
    @media screen and (max-width: 767px) {
        font-size: 17px;
        line-height: 24px;
    }
`;
const Footer = styled.div`
    display: block;
    margin-top: 32px;
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
    }
`;
export default function Highlight({
    name,
    title,
    description,
    image,
    button_title,
    url,
}) {
    return (
        <Container>
            <IntroContainer>
                <ImgContainer>
                    <LazyLoadImage
                        alt={title}
                        effect="blur"
                        src={image} // use normal <img> attributes as props
                        width={'100%'}
                        height={'auto'}
                        style={{ objectFit: 'contain' }}
                    />
                </ImgContainer>
                <Content>
                    <div>
                        <GoldenText>{name}</GoldenText>
                        <Heading>{title}</Heading>
                        <Desc>{description}</Desc>
                        <Footer>
                            <GoldenButton type="url" url={url}>
                                {button_title}
                            </GoldenButton>
                        </Footer>
                    </div>
                </Content>
            </IntroContainer>
        </Container>
    );
}
